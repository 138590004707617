import type { FormEvent, JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Session, SESSION_OTP_CHAR_COUNT } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  ButtonValid,
  Checkbox,
  EAlertV2Type,
  Form,
  Input,
} from '@feathr/components';
import { flattenError, flattenErrors, getQuery, logUserEvents } from '@feathr/hooks';
import type { IValidateGrouped } from '@feathr/rachis';
import { validate as validatejs } from '@feathr/rachis';

import Page from '../../Components/Page';
import { redirect } from '../App';

import * as styles from './OTPPage.css';

interface ILoginWithOTP {
  email: string;
  password: string;
  verificationCode: string;
  isTrusted: boolean;
}

function validate({
  email,
  password,
  verificationCode,
  isTrusted,
}: ILoginWithOTP): IValidateGrouped {
  const constraints = {
    email: {
      presence: true,
      email: true,
    },
    password: {
      presence: true,
    },
    verificationCode: {
      presence: {
        allowEmpty: false,
        message: '^Verification code is required.',
      },
      length: {
        is: SESSION_OTP_CHAR_COUNT,
        message: '^Verification code must be 8 characters.',
      },
    },
    isTrusted: {
      presence: true,
    },
  };
  const errors: IValidateGrouped =
    validatejs.validate({ email, password, verificationCode, isTrusted }, constraints, {
      format: 'grouped',
    }) ?? {};

  return errors;
}

function OTPPage(): JSX.Element {
  const location = useLocation<{ email: string; password: string }>();
  const { t } = useTranslation();

  const [hasAlert, setHasAlert] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState('');
  const [isTrusted, setIsTrusted] = React.useState(false);

  const { email, password } = location.state;

  const { from } = getQuery<{ from: string; next: string; return_to: string }>();
  const loginUrl = from ? `/?from=${encodeURIComponent(from)}` : '/';

  function handleChangeVerificationCode(newValue?: string): void {
    setVerificationCode(newValue ?? '');
  }

  function handleChangeTrustDevice(newValue: boolean = false): void {
    setIsTrusted(newValue);
  }

  async function handleVerifyCode(e: FormEvent<HTMLButtonElement>): Promise<void> {
    e.preventDefault();
    try {
      await Session.logIn(email, password, verificationCode, isTrusted);
      if (!Session.isLoggedIn) {
        throw new Error('Your login token is invalid.');
      }
      logUserEvents({ 'Logged in': null });
      redirect();
    } catch (error) {
      if (error instanceof AggregateError) {
        // If the response is a 403, the user is logged in but needs to verify their device using OTP.
        const is403 = error.errors.some((e) => e.status === 403);
        if (is403) {
          setHasAlert(true);
          return;
        }
      }
      throw error;
    }
  }

  async function handleResendCode(): Promise<void> {
    try {
      // To re-send, simply try to log in without a verification code.
      await Session.logIn(email, password, undefined, undefined);
      if (!Session.isLoggedIn) {
        throw new Error('Your login token is invalid.');
      }
    } catch (error) {
      if (error instanceof AggregateError) {
        // If the response is a 403, the user is logged in but needs to verify their device using OTP.
        const is403 = error.errors.some((e) => e.status === 403);
        if (is403) {
          // This is expected, so we don't need to show an alert.
          return;
        }
      }
      throw error;
    }
  }

  function flattenErrorIfPresent(
    errors?: string | string[] | IValidateGrouped | IValidateGrouped[],
  ): string[] | undefined {
    if (errors === undefined) {
      return undefined;
    }
    return flattenError(errors);
  }

  const validationErrors = validate({
    email,
    password,
    verificationCode,
    isTrusted,
  });

  return (
    <Page
      description={
        <Trans t={t} values={{ email }}>
          Enter your code sent to <strong>{{ email }}</strong>
        </Trans>
      }
      title={t('Enter Your Verification Code')}
    >
      <Form element={'form'} label={t('Login to Feathr With a One-Time Password')}>
        <Button className={styles.back} link={loginUrl} name={'back_to_login'} type={'link'}>
          {t('Log in with a different email')}
        </Button>
        {hasAlert && (
          <Alert
            description={t('Check your email for a new code.')}
            title={t('You are trying to log in from an untrusted device.')}
            type={EAlertV2Type.warning}
          />
        )}
        <Input
          label={t('Verification code')}
          maxLength={SESSION_OTP_CHAR_COUNT}
          onChange={handleChangeVerificationCode}
          validationError={flattenErrorIfPresent(validationErrors.verificationCode)}
          value={verificationCode}
        />
        <Checkbox
          label={t('Trust this device for 30 days')}
          onChange={handleChangeTrustDevice}
          validationError={flattenErrorIfPresent(validationErrors.isTrusted)}
          value={isTrusted}
        />
        <ButtonValid
          buttonType={'submit'}
          className={styles.button}
          errors={flattenErrors(validationErrors)}
          name={'verify_code'}
          onClick={handleVerifyCode}
          type={'primary'}
        >
          {t('Verify')}
        </ButtonValid>
        <p className={styles.resend}>
          {t('Didn’t receive the code?')}{' '}
          <Button name={'resend_code'} onClick={handleResendCode} type={'link'}>
            {t('Resend code')}
          </Button>
        </p>
        <p className={styles.reachout}>
          <Trans t={t}>
            Not receiving the email? Reach out to{' '}
            <a href={'mailto:support@feathr.co'}>support@feathr.co</a>
          </Trans>
        </p>
      </Form>
    </Page>
  );
}

export default OTPPage;
