import AggregateError from 'es-aggregate-error';
import type { FormEvent, JSX } from 'react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Session } from '@feathr/blackbox';
import { Button, Form, Input, toast } from '@feathr/components';
import { getQuery, logUserEvents } from '@feathr/hooks';

import Page from '../../Components/Page';
import { redirect } from '../App';

import * as styles from './LoginPage.css';

function LoginPage(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  if (Session.isLoggedIn) {
    redirect();
  }

  function handleEmailChange(newValue?: string): void {
    setEmail(newValue ?? '');
  }

  function handlePasswordChange(newValue?: string): void {
    setPassword(newValue ?? '');
  }

  async function handleLogin(e: FormEvent<HTMLButtonElement>): Promise<void> {
    const { from } = getQuery<{ from: string; next: string; return_to: string }>();
    e.preventDefault();
    try {
      await Session.logIn(email, password, undefined, undefined);
      if (!Session.isLoggedIn) {
        throw new Error('Your login token is invalid.');
      }
      logUserEvents({ 'Logged in': null });
      redirect();
    } catch (ee) {
      if (ee instanceof AggregateError) {
        const is403 = ee.errors.some((e) => e.status === 403);
        // If the response is a 403, the user is logged in but needs to verify their device using OTP.
        if (is403) {
          // Redirect to the /one-time-password page and pass along email and password.
          const url = from
            ? `/one-time-password?from=${encodeURIComponent(from)}`
            : '/one-time-password';
          history.push(url, { email, password });
          return;
        }
      }

      // If the response is a 401, the user is not logged in.
      toast('You were unable to sign in. Check your email and password, and try again.', {
        type: 'error',
      });
      // eslint-disable-next-line no-console
      console.error(ee);
    }
  }

  const termsAndConditions = (
    <a href={'https://www.feathr.co/terms'} rel={'noopener noreferrer'} target={'_blank'}>
      terms and conditions
    </a>
  );

  const privacyPolicy = (
    <a href={'https://www.feathr.co/privacy-policy'} rel={'noopener noreferrer'} target={'_blank'}>
      privacy policy
    </a>
  );

  const signInAgreement = (
    <p>
      By signing in, you are agreeing to our {privacyPolicy} and {termsAndConditions}.
    </p>
  );

  const footer = (
    <>
      Don't have an account?{' '}
      <a className={styles.demo} href={'https://feathr.co/request-a-demo'}>
        Request a demo
      </a>
    </>
  );

  return (
    <Page footer={footer}>
      <Form className={styles.root} element={'form'} label={'Login to Feathr'}>
        <Input
          label={'Email'}
          name={'email_address'}
          onChange={handleEmailChange}
          type={'text'}
          value={email}
        />
        <Input
          label={'Password'}
          name={'password'}
          onChange={handlePasswordChange}
          type={'password'}
          value={password}
        />
        {signInAgreement}
        <Button
          buttonType={'submit'}
          className={styles.signin}
          name={'sign_in'}
          onClick={handleLogin}
          type={'primary'}
        >
          Sign in
        </Button>
        <Button
          buttonType={'button'}
          link={'/forgot-password'}
          name={'forgot_password'}
          type={'link'}
        >
          Forgot your password?
        </Button>
      </Form>
    </Page>
  );
}

export default LoginPage;
